import type { FC, PropsWithChildren } from 'react'
import Link from 'next/link'
import styled from '@emotion/styled'

import SegmentLogo from '@segment/matcha/components/SegmentLogo'

import FooterRecipeBase, * as FooterRecipe from '@segment/matcha/recipes/Footer'

import data from './data'

const Footer: FC<PropsWithChildren> = ({ ...props }) => {
  return (
    <StyledFooterRecipe
      bottomLinks={data.bottomLinks}
      copyright={data.copyright}
      logo={
        <Link href="https://segment.com/" passHref={true}>
          <SegmentLogo alt="Twilio, Inc." />
        </Link>
      }
      {...props}
    />
  )
}

const StyledFooterRecipe = styled(FooterRecipeBase)`
  ${FooterRecipe.Content} {
    max-width: 1280px;
  }
`

export default Footer
