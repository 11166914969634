import { useEffect, useRef, useState } from 'react';

/**
 * A hook to observe elements in the viewport
 * @param ref - A ref to the element to observe
 * @param options - The options for the IntersectionObserver
 * @returns A boolean indicating if the element is in the viewport
 */
var useIsInView = function useIsInView(ref) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$stopOnIntersecti = _ref.stopOnIntersection,
      stopOnIntersection = _ref$stopOnIntersecti === void 0 ? true : _ref$stopOnIntersecti,
      options = _ref.options;

  var _useState = useState(false),
      isInView = _useState[0],
      setIsInView = _useState[1];

  var inViewRef = useRef(false);
  useEffect(function () {
    var intersectionObserver = new IntersectionObserver(function (intersection) {
      if (intersection.some(function (i) {
        return i.isIntersecting;
      })) {
        inViewRef.current = true;
        setIsInView(true);

        if (stopOnIntersection) {
          intersectionObserver.disconnect();
        }
      } else if (inViewRef.current) {
        inViewRef.current = false;
        setIsInView(false);
      }
    }, options);

    if (ref.current) {
      intersectionObserver.observe(ref.current);
    }

    return function () {
      intersectionObserver.disconnect();
    };
  }, [ref]);
  return isInView;
};

export default useIsInView;