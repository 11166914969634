const data = {
  bottomLinks: [
    {
      url: `https://www.twilio.com/legal/privacy/`,
      title: `Privacy policy`,
    },
    {
      url: `https://www.twilio.com/legal/tos/`,
      title: `Terms of Service`,
    },
  ],
  copyright: `© ${new Date().getUTCFullYear()} Twilio Inc. All Rights Reserved.`,
}

export default data
