import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _has from "lodash/has";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var isAsset = function isAsset(entry) {
  return typeof entry === "object" && entry !== null && _has(entry, "fields.file");
};

var isEntry = function isEntry(entry) {
  return typeof entry === "object" && entry !== null && "fields" in entry;
};

var transformContentfulResponse = function transformContentfulResponse(data) {
  return Object.entries(data).reduce(function (acc, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    if (isAsset(value)) {
      var _value$fields, _value$fields2, _value$fields3, _value$fields3$file$d, _value$fields4, _value$fields4$file$d;

      return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, key, {
        title: (_value$fields = value.fields) === null || _value$fields === void 0 ? void 0 : _value$fields.title,
        src: (_value$fields2 = value.fields) === null || _value$fields2 === void 0 ? void 0 : _value$fields2.file.url,
        width: (_value$fields3 = value.fields) === null || _value$fields3 === void 0 ? void 0 : (_value$fields3$file$d = _value$fields3.file.details.image) === null || _value$fields3$file$d === void 0 ? void 0 : _value$fields3$file$d.width,
        height: (_value$fields4 = value.fields) === null || _value$fields4 === void 0 ? void 0 : (_value$fields4$file$d = _value$fields4.file.details.image) === null || _value$fields4$file$d === void 0 ? void 0 : _value$fields4$file$d.height
      }));
    }

    if (isEntry(value)) {
      return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, key, transformContentfulResponse(value.fields)));
    }

    if (Array.isArray(value)) {
      return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, key, value.map(function (item) {
        if (isAsset(item)) {
          var _item$fields, _item$fields2, _item$fields3, _item$fields3$file$de, _item$fields4, _item$fields4$file$de;

          return {
            title: (_item$fields = item.fields) === null || _item$fields === void 0 ? void 0 : _item$fields.title,
            src: (_item$fields2 = item.fields) === null || _item$fields2 === void 0 ? void 0 : _item$fields2.file.url,
            width: (_item$fields3 = item.fields) === null || _item$fields3 === void 0 ? void 0 : (_item$fields3$file$de = _item$fields3.file.details.image) === null || _item$fields3$file$de === void 0 ? void 0 : _item$fields3$file$de.width,
            height: (_item$fields4 = item.fields) === null || _item$fields4 === void 0 ? void 0 : (_item$fields4$file$de = _item$fields4.file.details.image) === null || _item$fields4$file$de === void 0 ? void 0 : _item$fields4$file$de.height
          };
        }

        if (isEntry(item)) {
          return transformContentfulResponse(item.fields);
        }

        return item;
      })));
    }

    return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, key, value));
  }, {});
};

export default transformContentfulResponse;