import moment from 'moment-timezone'
import { useMemo } from 'react'

const FULL_FORMAT = `MMM. DD, hh:mma z`

const useLocalDate = (date: string, tz = `America/Los_Angeles`) => {
  return useMemo(() => {
    if (!date) {
      return ``
    }

    const localTimeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone

    const inputDateTime = moment.tz(date, tz)
    const localDateTime = inputDateTime.clone().tz(localTimeZone)

    return localDateTime.format(FULL_FORMAT)
  }, [date])
}

export default useLocalDate
