import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var _excluded = ["onClick"],
    _excluded2 = ["onClick"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useMemo } from 'react';
import { useClickAway } from 'react-use';
var DEFAULT_OPTIONS = {
  closeOnOverlayClick: true
};
var DEFAULT_EVENTS = ["mousedown", "touchstart"];

var useModal = function useModal(menuRef, _ref, options) {
  var _ref2 = _slicedToArray(_ref, 2),
      setOpened = _ref2[1];

  var settings = useMemo(function () {
    return _objectSpread(_objectSpread({}, DEFAULT_OPTIONS), options);
  }, [options]);

  var getControlOpenProps = function getControlOpenProps() {
    var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var _onClick = _ref3.onClick,
        rest = _objectWithoutProperties(_ref3, _excluded);

    return _objectSpread({
      onClick: function onClick(event) {
        setOpened(true);
        _onClick === null || _onClick === void 0 ? void 0 : _onClick(event);
      }
    }, rest);
  };

  var getControlCloseProps = function getControlCloseProps() {
    var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var _onClick2 = _ref4.onClick,
        rest = _objectWithoutProperties(_ref4, _excluded2);

    return _objectSpread({
      'aria-label': "Close the modal",
      onClick: function onClick(event) {
        setOpened(false);
        _onClick2 === null || _onClick2 === void 0 ? void 0 : _onClick2(event);
      }
    }, rest);
  };

  useClickAway(menuRef, function () {
    if (settings.closeOnOverlayClick) {
      setOpened(false);
    }
  }, settings.closeOnOverlayClick ? DEFAULT_EVENTS : []);
  return {
    getControlOpenProps: getControlOpenProps,
    getControlCloseProps: getControlCloseProps
  };
};

export default useModal;