import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useState, useEffect, useRef } from 'react';
import lottie from 'lottie-web';

/**
 * A hook that provides an animation instance and a ref to the container element.
 * @param options The options to configure the animation.
 * @returns The animation instance and a ref to the container element.
 */
var useLottie = function useLottie(_ref) {
  var options = _ref.options;
  var containerRef = useRef(null);

  var _useState = useState(null),
      animationInstance = _useState[0],
      setAnimationInstance = _useState[1];

  var initializedRef = useRef(false);
  useEffect(function () {
    if (!containerRef || !options.animationData || initializedRef.current) {
      return;
    }

    initializedRef.current = true;
    options.animationData.then(function (response) {
      return typeof response.json === "function" ? response.json() : response;
    }).then(function (data) {
      setAnimationInstance(lottie.loadAnimation(_objectSpread(_objectSpread({
        container: containerRef.current
      }, options), {}, {
        animationData: data
      })));
    });
    return function () {
      return animationInstance === null || animationInstance === void 0 ? void 0 : animationInstance.destroy();
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, options.animationData]);
  return [animationInstance, containerRef];
};

export default useLottie;