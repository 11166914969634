import useDomainPath from '../useDomainPath'

const useNavLinks = () => {
  const aboutLink = useDomainPath()
  const agendaLink = useDomainPath(`agenda`)
  const speakersLink = useDomainPath(`speakers`)

  return [
    {
      id: `about`,
      title: `About`,
      url: aboutLink || `/about/`,
    },
    {
      id: `agenda`,
      title: `Agenda`,
      url: agendaLink,
    },
    {
      id: `speakers`,
      title: `Speakers`,
      url: speakersLink,
    },
  ]
}

export default useNavLinks
